<template>
    <div class="systemCertification_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.sixAdvantagesName"> </CourseTitle>
            <div class="content sixAdvantages">
                <img :src="formData.sixAdvantagesImgUrl" alt="" />
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.chooseGolgaName"> </CourseTitle>
            <div class="content chooseGolga flex-center">
                <ul>
                    <li class="chooseGolga_list flex-center"
                        v-for="(item, index) in formData.systemCertificationChooseGolgas" :key="index">
                        <img :src="item.chooseGolgaImgUrl" alt="" />
                        <div class="name nowrap">{{ item.chooseGolgaName }}</div>
                    </li>
                </ul>
                <img class="dividingLine" src="./img/systemCertification/dividingLine.png" alt="" />
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.isoThreeCertificationName"> </CourseTitle>
            <div class="content ISO_system">
                <ul class="display">
                    <li v-for="(
							item, index
						) in formData.systemCertificationIsoThreeCertifications" :key="index">
                        <img :src="item.isoThreeCertificationImgUrl" alt="" />
                        <div class="li_main">
                            <div class="name">{{ item.isoThreeCertificationName }}</div>
                            <div class="text">
                                {{ item.isoThreeCertificationIntroduce }}
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="btn display pointer" @click="service">了解更多优势</div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.threeSystemCertificationName">
            </CourseTitle>
            <div class="content threeSystemCertificationMaterial Between flex-wrap">
                <div class="threeSystem_div flex" v-for="(item, index) in formData.systemCertificationThreeSystems"
                    :key="index">
                    <div class="fl display">
                        <p>{{ item.threeSystemName }}</p>
                        <img class="icon_sjx" src="./img/systemCertification/icon_sjx.png" alt="" />
                    </div>
                    <div class="fr">
                        <ul>
                            <li class="flex" v-for="(items, indexs) in item.systemCertificationThreeSystems" :key="indexs">
                                <div class="num display">0{{ indexs + 1 }}</div>
                                <div class="text">{{ items.threeSystemName }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btn display pointer" @click="service">
                免费下载资料清单及模板
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.popularSystemCertificationName">
            </CourseTitle>
            <div class="content hotSystem">
                <ul class="display">
                    <li class="hotSystem_li" v-for="(
							item, index
						) in formData.systemCertificationPopularSystemCertifications" :key="index">
                        <div class="name">{{ item.popularName }}</div>
                        <div class="text">
                            {{ item.popularIntroduce }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.serviceProcessName"> </CourseTitle>
            <div class="content serviceProcess">
                <ul class="display">
                    <li class="serviceProcess_li display column"
                        v-for="(item, index) in formData.systemCertificationServiceProcessS" :key="index">
                        <img src="./img/systemCertification/icon_right.png" alt="" class="icon_right" />
                        <img :src="item.serviceProcessImgUrl" alt="" class="li_img" />
                        <div class="serviceProcess_name">{{ item.serviceProcessName }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.preferentialOfferName"> </CourseTitle>
            <FreeCharge :status="13" :type="'企业服务体系认证'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import ProblemSolving from '@/components/problemSolving.vue'
import FreeCharge from '@/components/freeCharge'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ProblemSolving,
        FreeCharge,
    },
    data() {
        return {
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.systemCertificationApi().then((res) => {
                this.formData = res.data
            })
        },

        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.systemCertification_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
    }

    .module1 {
        height: 690px;

        .sixAdvantages {
            margin-top: 31px;

            img {
                height: 480px;
            }
        }
    }

    .module2 {
        height: 584px;
        background: url('./img/systemCertification/bg2.png') no-repeat;
        background-size: 100% 100%;

        .chooseGolga {
            .dividingLine {
                width: 1030px;
                height: 30px;
                margin: 0 auto;
                margin-top: 177px;
            }

            ul {
                position: relative;

                .chooseGolga_list:nth-child(1) {
                    top: -70px;
                    left: 229px;
                }

                .chooseGolga_list:nth-child(2) {
                    top: -70px;
                    left: 707px;
                }

                .chooseGolga_list:nth-child(3) {
                    top: 140px;
                    left: 129px;
                }

                .chooseGolga_list:nth-child(4) {
                    top: 140px;
                    left: 514px;
                }

                .chooseGolga_list:nth-child(5) {
                    top: 140px;
                    left: 842px;
                }

                .chooseGolga_list {
                    position: absolute;

                    img {
                        width: 100px;
                        height: 100px;
                    }

                    .name {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .module3 {
        height: 750px;

        .ISO_system {
            margin-top: 31px;

            ul {
                li {
                    width: 380px;
                    height: 394px;
                    background: #ffffff;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    margin: 0px 14px;

                    img {
                        width: 380px;
                        height: 260px;
                    }

                    .li_main {
                        width: 100%;
                        height: 134px;
                        padding: 30px 14px;
                        box-sizing: border-box;
                        margin-top: -4px;

                        .name {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 24px;
                        }

                        .text {
                            width: 352px;
                            height: 39px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 15px;
                        }
                    }
                }
            }

            .btn {
                margin-top: 70px;
            }
        }
    }

    .module4 {
        height: 815px;
        background: url('./img/systemCertification/bg3.png') no-repeat;
        background-size: 100% 100%;

        .threeSystemCertificationMaterial {
            margin-top: 41px;

            .threeSystem_div {
                width: 580px;
                // height: 200px;
                background: #ffffff;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                border-radius: 5px;

                .fl {
                    width: 160px;
                    background: linear-gradient(-30deg, #3186e3, #004795);
                    position: relative;
                    padding: 10px;

                    p {
                        width: 120px;
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 30px;
                        margin-top: -10px;
                    }

                    .icon_sjx {
                        width: 10px;
                        height: 16px;
                        position: absolute;
                        top: 45%;
                        right: -10px;
                    }
                }

                .fr {
                    padding: 23px 0px 10px 18px;
                    box-sizing: border-box;

                    ul {
                        li {
                            margin-bottom: 8px;

                            .num {
                                width: 26px;
                                height: 22px;
                                background: url('./img/systemCertification/icon_num.png') no-repeat;
                                background-size: 100% 100%;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #ffffff;
                            }

                            .text {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                line-height: 30px;
                                margin-left: 13px;
                                margin-top: -5px;
                            }
                        }
                    }
                }
            }

            .threeSystem_div:nth-child(1) {
                .fl {
                    p {
                        text-indent: 1.2rem;
                    }
                }
            }

            .threeSystem_div:nth-child(3) {
                width: 1199px;
                background: #ffffff;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                margin-top: 25px;

                .fl {
                    min-width: 230px;
                    background: linear-gradient(-30deg, #3186e3, #004795);
                    position: relative;

                    p {
                        width: 173px;
                        height: 49px;
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 30px;
                    }

                    .icon_sjx {
                        width: 10px;
                        height: 16px;
                        position: absolute;
                        top: 48%;
                        right: -10px;
                    }
                }

                .fr {
                    padding: 23px 21px 10px 46px;
                    box-sizing: border-box;

                    ul {
                        li {
                            width: 450px;
                            margin-bottom: 8px;

                            .num {
                                width: 26px;
                                height: 22px;
                                background: url('./img/systemCertification/icon_num.png') no-repeat;
                                background-size: 100% 100%;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #ffffff;
                            }

                            .text {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                line-height: 30px;
                                margin-left: 18px;
                                margin-top: -5px;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            margin-top: 50px;
        }
    }

    .module5 {
        height: 506px;

        .hotSystem {
            margin-top: 41px;

            .hotSystem_li:nth-child(1) {
                margin-left: 0 !important;
            }

            .hotSystem_li {
                width: 182px;
                height: 240px;
                background: url('./img/systemCertification/bg4.png') no-repeat;
                background-size: 100% 100%;
                padding-top: 52px;
                padding-left: 32px;
                box-sizing: border-box;
                margin-left: 131px;

                .name {
                    width: 219px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .text {
                    width: 208px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    margin-top: 10px;
                }
            }
        }
    }

    .module6 {
        height: 430px;

        .serviceProcess {
            margin-top: 35px;

            ul {
                .serviceProcess_li:last-child {
                    margin-right: 0 !important;

                    .icon_right {
                        display: none !important;
                    }
                }

                .serviceProcess_li {
                    width: 134px;
                    position: relative;
                    margin-right: 80px;

                    .icon_right {
                        width: 34px;
                        height: 16px;
                        position: absolute;
                        top: 52px;
                        right: -44px;
                    }

                    .li_img {
                        width: 134px;
                        height: 114px;
                    }

                    .serviceProcess_name {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 34px;
                    }
                }
            }
        }
    }

    .module7 {
        background: #fafafa;
    }
}</style>